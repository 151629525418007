import { combineReducers } from "redux";
import alert from "./alert";
import auth from "./auth";
import profile from "./profile";
import post from "./post";
// new admin site
import driver from "./admin/driver";
import vehicle from "./admin/vehicle";
import booking from "./admin/booking";
import navtime from "./navtime";
import vehicletype from "./admin/vehicletype";
import setting from "./admin/settings";
import trippackage from "./admin/package";
import airport from "./admin/airport";
import category from "./admin/category";
import template from "./admin/emailtemplate";
import cmspage from "./admin/cmspage";
import cancelreschedule from "./admin/cancelreschedule";

import customerBooking from "./customerBooking";
import notification from "./notification";
export default combineReducers({
  alert,
  auth,
  profile,
  post,
  driver,
  vehicle,
  booking,
  navtime,
  vehicletype,
  setting,
  trippackage,
  airport,
  category,
  template,
  cmspage,
  customerBooking,
  cancelreschedule,
  notification
});
