import {
  ADD_BOOKING,
  BOOKING_ERROR,
  GET_BOOKINGS,
  GET_BOOKING,
  ON_GET_BOOKING,
  UPDATE_BOOKING_STATUS,
  UPDATE_BOOKING_DRIVER,
  CHANGE_BOOKING_VEHICLE,
  ADD_BOOKING_NOTE,
  ADD_SINGLE_FORM_BOOKING,
  ADD_MULTI_FORM_BOOKING,
  ADD_AIRPORT_FORM_BOOKING,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  ADD_CLOCK_REF,
  GET_CALANDER_BOOKINGS,
  GET_CART,
  ADD_BY_THE_HOUR_FORM_BOOKING,
  ADD_SPECIFIED_DESTINATION_FORM_BOOKING,
} from "../../actions/types";

const initialState = {
  bookings: [],
  cart:
    typeof window !== "undefined"
      ? JSON.parse(localStorage.getItem("cart"))
      : [],
  booking: null,
  calanderBooking: null,
  loading: true,
  error: {},
  singleFormData: null,
  multiFormData: null,
  airportFormData: null,
  byTheHourFormData: null,
  specifiedDestinationFormData : null,
  clockRefState: null,
  adminCart: [],
};

export default function foo(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_CART:
      return {
        ...state,
        adminCart: payload,
        loading: false,
      };
    case GET_CALANDER_BOOKINGS:
      return {
        ...state,
        calanderBooking: payload,
        loading: false,
      };
    case ADD_CLOCK_REF:
      return {
        ...state,
        clockRefState: payload,
        loading: false,
      };
    case REMOVE_FROM_CART:
    case ADD_TO_CART:
      return {
        ...state,
        cart: payload,
        loading: false,
      };
    case ADD_SINGLE_FORM_BOOKING:
      return {
        ...state,
        singleFormData: payload,
        loading: false,
      };
    case ADD_BY_THE_HOUR_FORM_BOOKING:
      return {
        ...state,
        byTheHourFormData: payload,
        loading: false,
      };
    case ADD_SPECIFIED_DESTINATION_FORM_BOOKING:
      return {
        ...state,
        specifiedDestinationFormData: payload,
        loading: false,
      };
    case ADD_MULTI_FORM_BOOKING:
      return {
        ...state,
        multiFormData: payload,
        loading: false,
      };
    case ADD_AIRPORT_FORM_BOOKING:
      return {
        ...state,
        airportFormData: payload,
        loading: false,
      };
    case ADD_BOOKING_NOTE:
      return {
        ...state,
        booking: payload,
        loading: false,
      };
    case CHANGE_BOOKING_VEHICLE:
      return {
        ...state,
        booking: payload,
        loading: false,
      };
    case UPDATE_BOOKING_DRIVER:
      return {
        ...state,
        booking: payload,
        loading: false,
      };
    case UPDATE_BOOKING_STATUS:
      return {
        ...state,
        booking: payload,
        loading: false,
      };
    case ADD_BOOKING:
      return {
        ...state,
        booking: payload,
        loading: false,
      };
    case GET_BOOKING:
      return {
        ...state,
        booking: payload,
        loading: false,
      };
    case GET_BOOKINGS:
      return {
        ...state,
        bookings: payload,
        loading: false,
      };
    case BOOKING_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
      case ON_GET_BOOKING:
        return {
          ...state,
          loading: true,
        };
    default:
      return state;
  }
}
