export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const SET_ALERT_EDIT_BOOKING = "SET_ALERT_EDIT_BOOKING";
export const REMOVE_ALERT_EDIT_BOOKING = "REMOVE_ALERT_EDIT_BOOKING";
export const REGISTER_SUCESS = "REGISTER_SUCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const ALL_USERS = "ALL_USERS";
export const EDIT_USER = "EDIT_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const LOGOUT_ADMIN = "LOGOUT_ADMIN";
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const ACCOUNT_DELETED = "ACCOUNT_DELETED";
export const GET_REPOS = "GET_REPOS";
export const GET_POSTS = "GET_POSTS";
export const GET_POST = "GET_POST";
export const POST_ERROR = "POST_ERROR";
export const UPDATE_LIKES = "UPDATE_LIKES";
export const DELETE_POST = "DELETE_POST";
export const ADD_POST = "ADD_POST";
export const ADD_COMMENT = "ADD_COMMENT";
export const REMOVE_COMMENT = "REMOVE_COMMENT";

export const ADD_DRIVER = "ADD_DRIVER";
export const GET_DRIVERS = "GET_DRIVERS";
export const GET_DRIVER = "GET_DRIVER";
export const DRIVER_ERROR = "DRIVER_ERROR";

export const ADD_VEHICLE = "ADD_VEHICLE";
export const GET_VEHICLES = "GET_VEHICLES";
export const GET_VEHICLE = "GET_VEHICLE";
export const GET_ACTIVE_VEHICLES = "GET_ACTIVE_VEHICLES";
export const VEHICLE_ERROR = "VEHICLE_ERROR";
export const UPDATE_SETTINGS = "UPDATE_SETTINGS";
export const SET_LANG = "SET_LANG";

export const GET_SETTING = "GET_SETTING";
export const SET_REF = "SET_REF";
export const UPDATE_SETTINGS_ERROR = "UPDATE_SETTINGS_ERROR";

export const ADD_PACKAGE = "ADD_PACKAGE";
export const DELETE_PACKAGE = "DELETE_PACKAGE";
export const GET_PACKAGES = "GET_PACKAGES";
export const GET_PACKAGE = "GET_PACKAGE";
export const PACKAGE_ERROR = "VEHICLE_ERROR";

export const DELETE_NEW_PACKAGE = "DELETE_NEW_PACKAGE";
export const GET_NEW_PACKAGES = "GET_NEW_PACKAGES";

export const ADD_VEHICLE_TYPE = "ADD_VEHICLE_TYPE";
export const GET_VEHICLE_TYPES = "GET_VEHICLE_TYPES";
export const GET_VEHICLE_TYPE = "GET_VEHICLE_TYPE";
export const VEHICLE_TYPE_ERROR = "VEHICLE_TYPE_ERROR";

export const ON_GET_INDIVIDUAL_BOOKINGS = "ON_GET_INDIVIDUAL_BOOKINGS";
export const GET_INDIVIDUAL_BOOKINGS = "GET_INDIVIDUAL_BOOKINGS";
export const GET_BOOKINGS = "GET_BOOKINGS";
export const GET_BOOKING = "GET_BOOKING";
export const ON_GET_BOOKING = "ON_GET_BOOKING";

export const ADD_BOOKING = "ADD_BOOKING";
export const UPDATE_BOOKING_STATUS = "UPDATE_BOOKING_STATUS";
export const UPDATE_BOOKING_DRIVER = "UPDATE_BOOKING_DRIVER";
export const CHANGE_BOOKING_VEHICLE = "CHANGE_BOOKING_VEHICLE";
export const ADD_BOOKING_NOTE = "ADD_BOOKING_NOTE";
export const BOOKING_ERROR = "BOOKING_ERROR";
export const INDIVIDUAL_BOOKINGS_ERROR = "INDIVIDUAL_BOOKINGS_ERROR";

export const GET_ADDRESS_LIST = "GET_ADDRESS_LIST";
export const NAVTIME_ERROR = "NAVTIME_ERROR";

export const ADD_SINGLE_FORM_BOOKING = "ADD_SINGLE_FORM_BOOKING";
export const ADD_MULTI_FORM_BOOKING = "ADD_MULTI_FORM_BOOKING";
export const ADD_AIRPORT_FORM_BOOKING = "ADD_AIRPORT_FORM_BOOKING";
export const ADD_BY_THE_HOUR_FORM_BOOKING = "ADD_BY_THE_HOUR_FORM_BOOKING";
export const ADD_SPECIFIED_DESTINATION_FORM_BOOKING = "ADD_SPECIFIED_DESTINATION_FORM_BOOKING";

export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const GET_CART = "GET_CART";

export const GET_AIRPORTS = "GET_AIRPORTS";
export const AIRPORT_ERROR = "AIRPORT_ERROR";
export const GET_AIRPORT = "GET_AIRPORT";
export const DELETE_AIRPORT = "DELETE_AIRPORT";

export const GET_CATEGORIES = "GET_CATEGORIES";
export const CATEGORY_ERROR = "CATEGORY_ERROR";
export const GET_CATEGORY = "GET_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";

export const ADD_CLOCK_REF = "ADD_CLOCK_REF";
export const GET_CALANDER_BOOKINGS = "GET_CALANDER_BOOKINGS";

export const GET_TEMPLATE = "GET_TEMPLATE";
export const GET_TEMPLATES = "GET_TEMPLATES";
export const TEMPLATE_ERROR = "TEMPLATE_ERROR";

export const ADD_PAGE_CONTENT = "ADD_PAGE_CONTENT";
export const GET_PAGE_CONTENT = "GET_PAGE_CONTENT";
export const ON_GET_PAGE_CONTENT = "ON_GET_PAGE_CONTENT";

export const PAGE_CONTENT_ERROR = "PAGE_CONTENT_ERROR";

export const ADD_CANCEL_RESCHEDULE_REQUEST = "ADD_CANCEL_RESCHEDULE_REQUEST";
export const CANCEL_RESCHEDULE_REQUEST_ERROR = "CANCEL_RESCHEDULE_REQUEST_ERROR";
export const GET_CANCEL_RESCHEDULE_REQUESTS = "GET_CANCEL_RESCHEDULE_REQUESTS";
export const GET_CANCEL_RESCHEDULE_REQUEST = "GET_CANCEL_RESCHEDULE_REQUEST";
export const ADD_RESPONSE_CANCEL_RESCHEDULE_REQUEST = "ADD_RESPONSE_CANCEL_RESCHEDULE_REQUEST";
export const GET_ACTIVE_CANCEL_RESCHEDULE_REQUESTS = "GET_ACTIVE_CANCEL_RESCHEDULE_REQUESTS";

export const GET_NOTIFICATIONS_CUSTOMER = "GET_NOTIFICATIONS_CUSTOMER"
export const GET_NOTIFICATION_CUSTOMER = "GET_NOTIFICATION_CUSTOMER"
export const GET_NOTIFICATIONS_CUSTOMER_ERROR = "GET_NOTIFICATIONS_CUSTOMER_ERROR"
